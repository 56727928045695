.projectsCont h2{
    margin: 0;
    text-align: center;
    font-size: clamp(1.8rem, 5vw - 0.5rem, 3.5rem);
    color: var(--light-grey);
}

.projectsCont p{
    font-family: 'DM sans';
    margin: 0;
    font-weight: 300;
    text-align: center;
    color: var(--pale-green);
}

/* .projectsCont figure{
  
    position: relative;
    border-radius: 1rem;
margin: 0;
gap: 2rem;
margin-top: 2rem;
width: auto;
} */



.projectCont{

    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
}


.projectsCont p{
    width: 90%;
align-self: left;
padding-left: 1rem;
font-size: clamp(0.6rem, 5vw - 0.5rem, 1.1rem);
gap: 2rem;
text-align: left;
}
.projectsCont ul{
    display: flex;
    width: auto;
    align-items: center;
    text-align: center;
    list-style-type: none;
    margin: 0;
    font-size: clamp(0.6rem, 5vw - 0.5rem, 0.8rem);
padding-right: 1rem;
    padding-left:0;
    gap: 1rem;
    /* background-color: var(--teal-blue-02); */
justify-content: right;
    color: var(--light-grey);
    padding-top: 0.8rem;
    padding-bottom: 0.6rem;


}

 .status{
    font-size: clamp(1.2rem, 5vw - 0.5rem, 2.5rem);

color: var(--pale-green);
font-weight: 400;
 display: flex;
 justify-content: center;
 align-items: center;
position: absolute;
z-index: 100 ;
}



  
 img{
    position: relative;

display: flex;
justify-content: center;
align-items: center;
border-radius: 0.5rem;
  width: 100%;
}

.imgCont{
    position: relative;
    border-radius: 0.5rem;

}

.imgCont::after{
    content: '';
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
   left: 0;
    border: 1px solid var(--pale-green);
}
 .inProgress {
    position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 0.5rem;


}

.inProgress::after {
  content: '';
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid var(--pale-green);

  left: 0;
  background-color: rgba(0, 0, 0, 0.705); /* Overlay color */
}



.projectCont li{
    text-align: center;
}


 .projectCont::before {
    content: '';
    position: absolute;
    top: -0.7rem;
    left: -0.9em;
    right: -0.9rem;
    bottom: -0.7rem;
    border-radius: 0.6rem; /* slightly larger than the image border-radius */
    border: 1px solid var(--light-grey);
    z-index: 0; /* to place it behind the image */
    box-sizing: border-box; /* ensures the border is included in the size */
    pointer-events: none; 

  }

.projectsCont{

    display: flex;
    align-self: center;
flex-direction: column;
      width: 60%;
      gap: 5rem;
      justify-content: center;
      align-items: center;
}

@media(max-width: 900px){

    .projectsCont{
      display: flex;
      align-self: center;
  flex-direction: column;
        width: 80%;
        justify-content: center;
        align-items: center;
    }
}