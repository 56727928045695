



.header {
    width: 100%;



    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }
  
  
  
  .nav{
    z-index: 5;
 padding: 2rem 0rem 3rem;
   align-items: center;
   font-size: 1rem;
    justify-content: center;
font-weight: 200;
   width: 35rem;
 
  /* box-shadow:  3px 1px 5px 3px rgba(0, 0, 0, 0.227);  */
  cursor: pointer;
   
  }
 
  
  
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
  width: 12rem;
    height: auto;
    padding: 2rem 0rem  1.5rem;
  }



  
  .nav a:link,
  .nav a:visited {
    text-decoration: none;
   
    font-size: 1.08rem;
    font-weight: 600;
  }

  .nav a:global(.active) {
padding: 0.5rem 1rem;
border: 1.3px solid var(--pale-green) ;
border-radius: 2rem;
  

  }

  .nav a{
    padding: 0.3rem;
    color: var(--light-grey);
   
  }
.nav li{
  font-size: 2rem;
}


 

.links{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: left;
  gap: 0.4rem;
  margin: 0;
}


.links a:link{
  text-decoration: none;



}

.links a{
  color: #2e2f36;
  font-weight: 400;
  
}



 nav ul{
  list-style-type: none;
  align-items: center;
align-items: left;
display: flex;
justify-content: center;

gap: 3.5rem;
padding: 0;
font-family: 'Helvetica';
font-weight: 500;
color: #44464d;

margin: 0.2 ;
}




@media (max-width: 580px){
  
  .logo img{
    width: 12rem;
  }
.nav{

  display: none;
}
  .mobileNav{
 
    position: fixed;
    width: 100%;
z-index: 200;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .mobileNav ul{
    list-style-type: none;
    background: radial-gradient(circle at 0% 10%, rgba(31, 36, 33, 1) 30%,  rgba(33, 104, 105, 1) 190%);


    margin: 0;  
height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
z-index: 20000;
align-items: center;
transition: transform 0.5s ease-in-out;
  gap: 1rem;
  padding: 0;
  font-family: 'Helvetica';
  font-weight: 500;
position: relative;

  }

  .mobileNav li{
    font-size: clamp(1.3rem, 5vw - 0.5rem, 2rem);
margin-bottom: 0.5rem;
font-weight: 600;
  }


  .show{
    transform: translateX(100%);
  }
  .mobileNav ul::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  
    opacity: 0.3;
    background-size: 500%;
    z-index: -1;
    background-position: center;
    background-repeat: repeat-y;
  }

  .mobileNav a:global(.active) {

    border-bottom: 2px solid var(--pale-green) ;
      
    
      }
    
      .mobileNav a{
        padding: 0.3rem;
        color: var(--pale-green);
       
      }

.isActive .bar:nth-child(2){
    opacity: 0;
  }
  
.isActive .bar:nth-child(1){
    -webkit-transform: translateY(1vw) rotate(45deg);
    -ms-transform: translateY(1vw) rotate(45deg);
    -o-transform: translateY(1vw) rotate(45deg);
    transform: translateY(1vw) rotate(45deg);
  }
  
.isActive .bar:nth-child(3){
    -webkit-transform: translateY(-2vw) rotate(-45deg);
    -ms-transform: translateY(-2vw) rotate(-45deg);
    -o-transform: translateY(-2vw) rotate(-45deg);
    transform: translateY(-2vw) rotate(-45deg);
  }

  .header{
    display: flex;
    flex-direction: column;
 
    padding-bottom: 6rem;
    margin-top: 1rem ;
  }


  .menuToggle{
  
  position: absolute;
 
  right: 3vw;
  top: 3vw;
  z-index: 300;
  cursor: pointer;
  }
  
  
  .bar{
                
      
    height: .5vw;
    width: 5vw;
    background-color: var(--pale-green);
     display: flex;
     
     cursor: pointer;

     margin: 1vw auto;
     -webkit-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     transition: all 0.3s ease-in-out;
   }
}