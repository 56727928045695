 .heroPage h2{
  
    font-family: "Cabin", sans-serif;
   font-weight: 700;
   text-align: center;
   color: var(--pale-green);
transform: translateY(-5%);
 transition: all 0.5s ease-in-out;
 opacity: 0;
}



.heroPage h2.active{
opacity: 1;
    transform: translateY(0%);
    transition: all 1s ease-in-out;
}





.heroSection{
    grid-column: 1;
margin: 0;
display: grid;
 align-items: center;

}


/* section::after{
    position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;


  filter: contrast(110%) brightness(100%);
  background: 
	linear-gradient(45deg, var(--dark-grey)5%,rgba(117, 76, 146, 0.751)100%),
	url("data:image/svg+xml,%3Csvg viewBox='0 0 360 360' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.0' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}
  */
  




.heroPage p{
    text-align: center;
  margin: 0;
    font-family: "Cabin", sans-serif;
   font-weight: 300;
   color: var(--light-grey);
   
 
   
}
.heroPage{
   z-index: 5;
    row-gap: 1.8rem;
    display: grid;
    justify-content: center;

grid-template-columns: 60%;

}

 .heroSection button{
    background-color: transparent;
    font-size: clamp();
    cursor: pointer;
    grid-row: 3;
    color: var(--pale-green);
    width: auto;
    border: var(--light-grey) 1.3px solid;
    border-radius: 2rem;
    padding: 0.7rem 2rem;
    justify-self: center;
  text-align: center;
}

/* styles.module.css or any other css file you're using */
.code {
    color: var(--light-grey);
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    font-size: 1.2em;
    font-family: 'Playfair', serif;
    font-style: italic;
}

.code::before,
.code::after {
    content: '';
    position: absolute;
    border: 1.5px solid var(--pale-green);

    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
}



.code::after {
    width: 100%;
    height: 70%;
    top: 13px;
    left: -2px;
    transform: rotate(deg);
}

.code::before {
    opacity: 0.8;
    width: 100%;
    height: 80%;
    top: 7px;
    left: -5px;
    transform: rotate(-2deg);
}


.design {
    color: var(--light-grey);
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    font-size: 1.2em;
    font-family: 'Playfair', serif;
    font-style: italic;
}

.design::before,
.design::after {
    content: '';
    position: absolute;
    border: 1.5px solid var(--pale-green);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}

.design::after {
    width: 100%;
    height: 95%;
    top: 1px;
    left: -2px;
    transform: rotate(-2deg);
}

.design::before {
    width: 100%;
    height: 90%;
    top: 5px;
    left: -4px;
    transform: rotate(3deg);
}

.skills{
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    margin-top: 6rem;
    display: grid;
  row-gap: 1rem;
  column-gap:6vw;
  padding-left: 2rem;
  padding-right: 2rem;
    grid-template-columns: AUTO 40%;
    justify-content: center;
    z-index: 5;
    /* background-color: rgba(156, 197, 161, 0.125); */

   
}


.skills h3{
margin: 0;
    grid-column: 1;
 font-size: clamp(1.8rem, 5vw - 0.5rem, 3rem);
 font-weight: 300;
 align-self: first baseline;
    color: var(--pale-green);
    text-align: right;
}

.skills .intro{
    grid-column: 2;
    margin-top: 1rem;
    color: var(--light-grey);
    font-family: 'DM sans';
font-weight: 200;
    font-size: clamp(1rem, 5vw - 0.5rem, 1.3rem);
    
}
.skills p {

    grid-column: 2;
  
}


.skills figure{
    grid-column: 1/2 span;
  
    width: fit-content;
    margin: 0;
    width:40%;
}
.skills img{
width:30vw;
}



 .develop{
    margin-top: 1rem;
    margin-bottom: 0;
    color: rgb(143, 225, 150);
    font-weight: 500;
  
}

 .dsgn{
    margin-top: 0;
    font-weight: 500;
    color: rgb(143, 225, 150);
    margin-bottom: 3rem !important;
}

.skils, .highlight{
    color: rgb(143, 225, 150);
    font-weight: 500;

}

span:hover{
    transform: translateX(-5%);
} 

@media(max-width: 700px){
    .heroPage{
       
     
     grid-template-columns: 90%;
     
     }

     .skills h3{
        grid-column:  1/2 span;
text-align: left;
     }

     .skills .intro, div{
      
     grid-column:  2/3 span;
     }

    
}