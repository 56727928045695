fieldset{
    border: none;
    text-decoration: none;
    display: grid;
   row-gap: 1rem;
    width: 40%;
}

.labelCont{
    width: 100%;
}
form{
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

label{
margin-left: 1rem;
margin-bottom: 0;
    display: flex;
  color: var(--light-grey);
  font-weight: 300;
  font-family: 'DM sans';
    flex-direction: column;
}


.labelCont{
    display: flex;
    flex-direction: column;
gap: 0.5rem;
}


input{
    z-index: 100;
height: 2.4rem;
background-color: transparent;
border: 1px solid var(--light-grey);
border-radius: 0.3rem;
}

textarea{
    z-index: 100;
    height: 10rem;
background-color: transparent;
border: 1px solid var(--light-grey);
border-radius: 0.3rem;
}

legend{
    color: var(--light-grey);
    font-size: clamp(1rem, 5vw - 0.5rem, 3rem);
    padding-bottom: 3.5rem;
    text-align: center;
}

form button{
    z-index: 100;
    background-color: var(--light-grey);
background-image: linear-gradient(50deg, var(--light-grey) 4%, var(--dark-grey) 100%);
    font-size: clamp();
 width: 100%;
    color: var(--dark-grey);
margin-top: 2rem;
    border: 1px  solid var(--light-grey);
    font-weight: 600;
  font-family: 'DM sans';
    border-radius: 0.3rem;
    padding: 0.7rem 2rem;
    justify-self: center;
  text-align: center;
}